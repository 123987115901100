const drivers = [
    {
        order: 1,
        firstName: 'Michael',
        lastName: 'Wellner',
        number: 49,
        position: 'Fahrer, <strong>Teamchef</strong>, <strong>Gründer</strong>, Liveries, IT',
        lfmId: 18003,
        since: '11/2022',
        currentSim: 'iRacing'
    },
    {
        order: 2,
        firstName: 'Eric',
        lastName: 'Dietrich',
        number: 96,
        position: 'Fahrer, <strong>Gründer</strong>',
        lfmId: 33962,
        since: '11/2022',
        currentSim: 'iRacing'
    },
    {
        order: 3,
        firstName: 'Rick',
        lastName: 'Radek',
        number: 43,
        position: 'Fahrer',
        lfmId: 13227,
        since: '12/2022',
        currentSim: 'ACC'
    },
    {
        order: 4,
        firstName: 'Lukas',
        lastName: 'Schröcker',
        number: 95,
        position: 'Fahrer',
        lfmId: 7397,
        since: '12/2022',
        currentSim: 'ACC'
    },
    {
        order: 5,
        firstName: 'David',
        lastName: 'Jansen',
        number: 11,
        position: 'Fahrer',
        lfmId: null,
        since: '02/2023',
        currentSim: 'iRacing'
    },
    {
        order: 6,
        firstName: 'Josef',
        lastName: 'Keufl',
        number: 42,
        position: 'Fahrer',
        lfmId: 12645,
        since: '06/2023',
        currentSim: 'ACC'
    },
    {
        order: 7,
        firstName: 'Denis "Kovi"',
        lastName: 'Kovacec',
        number: 189,
        position: 'Fahrer',
        lfmId: 7973,
        since: '11/2023',
        currentSim: 'iRacing'
    },
    {
        order: 8,
        firstName: 'Markus',
        lastName: 'Rauer',
        number: 51,
        position: 'Fahrer',
        lfmId: 66230,
        since: '01/2024',
        currentSim: 'ACC'
    },
    {
        order: 9,
        firstName: 'Benjamin',
        lastName: 'Voigt',
        number: 7,
        position: 'Fahrer',
        lfmId: null,
        since: '01/2024',
        currentSim: 'iRacing'
    },
    {
        order: 10,
        firstName: 'Martin',
        lastName: 'Jainta',
        number: 6,
        position: 'Fahrer',
        lfmId: 77267,
        since: '02/2024',
        currentSim: 'ACC'
    },
    {
        order: 11,
        firstName: 'Alex',
        lastName: 'Binder',
        number: 26,
        position: 'Fahrer',
        lfmId: null,
        since: '04/2024',
        currentSim: 'iRacing'
    },
    {
        order: 12,
        firstName: 'David',
        lastName: 'Bunger',
        number: 8,
        position: 'Fahrer',
        lfmId: null,
        since: '07/2024',
        currentSim: 'iRacing'
    },
    {
        order: 13,
        firstName: 'Niklas',
        lastName: 'Ude',
        number: 117,
        position: 'Fahrer',
        lfmId: null,
        since: '07/2024',
        currentSim: 'iRacing'
    },
    {
        order: 14,
        firstName: 'Max',
        lastName: 'Mayer',
        number: 25,
        position: 'Fahrer, <strong>iRacing Manager</strong>',
        lfmId: null,
        since: '07/2024',
        currentSim: 'iRacing'
    },
    {
        order: 15,
        firstName: 'Michael',
        lastName: 'Borger',
        number: 56,
        position: 'Fahrer',
        lfmId: null,
        since: '09/2024',
        currentSim: 'iRacing'
    },
    {
        order: 16,
        firstName: 'Sebastian',
        lastName: 'Freese',
        number: 22,
        position: 'Fahrer',
        lfmId: 41560,
        since: '09/2024',
        currentSim: 'ACC'
    },
    {
        order: 17,
        firstName: 'Pascal',
        lastName: 'Hoffmann',
        number: 34,
        position: 'Fahrer',
        lfmId: null,
        since: '10/2024',
        currentSim: 'iRacing'
    },
    {
        order: 18,
        firstName: 'Raphael',
        lastName: 'Heck',
        number: 15,
        position: 'Fahrer',
        lfmId: null,
        since: '10/2024',
        currentSim: 'iRacing'
    },
    {
        order: 19,
        firstName: 'Dominic',
        lastName: 'Nöckel',
        number: 21,
        position: 'Fahrer',
        lfmId: null,
        since: '10/2024',
        currentSim: 'iRacing'
    },
    {
        order: 20,
        firstName: 'Kimon',
        lastName: 'Sapini',
        number: 777,
        position: 'Fahrer',
        lfmId: null,
        since: '11/2024',
        currentSim: 'iRacing'
    },
];

export default drivers.sort((a, b) => a.order > b.order ? 1 : -1);