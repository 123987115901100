const liveriesIR = [
    {
        order: 1,
        text: 'GT3 Default Liveries:<br>McLaren 720S GT3 Evo, Ford Mustang GT3,<br>Chevrolet Corvette Z06 GT3, Porsche 992 GT3 R,<br>Ferrari 296 GT3, Audi R8 Evo 2 GT3,<br>BMW M4 GT3, Mercedes AMG GT3',
        code: 'GT3',
        active: true,
    },
    {
        order: 2,
        text: 'GTP Default Liveries:<br>Cadillac V GTP, Porsche 963 GTP,<br>Acura ARX-06 GTP, BMW M V8 GTP',
        code: 'GTP',
        active: true,
    },
    {
        order: 3,
        text: 'LMP Default Liveries:<br>Dallara P217, Ligier JSP320',
        code: 'LMP',
        active: true,
    },
    {
        order: 4,
        text: 'GT4 Default Liveries:<br>Mercedes AMG GT4, Porsche Cayman GT4<br>BMW M4 GT4 Evo',
        code: 'GT4',
        active: true,
    },
    {
        order: 5,
        text: 'CUP Default Liveries:<br>Porsche 992 Cup, Renault CLio,<br>Toyota GR86, Global Mazda MX5 2016',
        code: 'CUP',
        active: true,
    },
    {
        order: 6,
        text: 'TCX Default Liveries:<br>Audi RS3 LMS, Honda Civic',
        code: 'TCX',
        active: true,
    },
    {
        order: 7,
        text: 'FORMEL Default Liveries:<br>Super Formula SF23 Toyota, Super Formula SF23 Honda,<br>Super Formula Lights, Fia F4,<br>Ray FF 1600',
        code: 'FORMEL',
        active: true,
    },
    {
        order: 8,
        text: 'OVAL Default Liveries:<br>Arca, Mini Stock',
        code: 'OVAL',
        active: true,
    },
    {
        order: 9,
        text: 'RADICAL Default Liveries:<br>Radical SR8, Radical SR10',
        code: 'RADICAL',
        active: true,
    },
    {
        order: 10,
        text: 'SUPERCAR Default Liveries:<br>Ford Mustang GT',
        code: 'SUPERCAR',
        active: true,
    },
    {
        order: 11,
        text: 'DIRT Default Liveries:<br>Lucas Oil Truck Pro 2 Lite',
        code: 'DIRT',
        active: true,
    },
    {
        order: 12,
        text: 'VINTAGE Default Liveries:<br>Audi 90 GTO, Lotus 79',
        code: 'VINTAGE',
        active: true,
    },
];

export default liveriesIR.sort((a, b) => a.order > b.order ? 1 : -1);
